<template>
  <div class="radio-wrapper">
    <input
      type="radio"
      :name="id"
      class="off"
      value="disabled"
      :id="'radio-off-' + id"
      v-model="state"
      @change="handleInput"
      :disabled="disabled"
    />
    <label :for="'radio-off-' + id">OFF</label>

    <input
      type="radio"
      :name="id"
      class="on"
      value="enabled"
      :id="'radio-on-' + id"
      v-model="state"
      @change="handleInput"
      :disabled="disabled"
    />
    <label :for="'radio-on-' + id">ON</label>
  </div>
</template>
<script>
export default {
  name: 'ciam-two-states',
  props: {
    value: {
      type: String,
      // default: 'enabled',
      validator(value) {
        return ['disabled', 'enabled'].includes(value);
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    value(newValue, oldValue) {
      this.state = newValue;
    },
  },
  components: {},
  data() {
    return {
      state: 'enabled',
      id: 'state-' + Math.floor(10000000 * Math.random()),
    };
  },
  methods: {
    handleInput(e) {
      this.$emit('input', this.state);
    },
  },
  mounted() {
    this.state = this.value;
  },
};
</script>

<style lang="scss" scoped>
$option-width: 60px;

.radio-wrapper {
  width: (#{$option-width * 2});
  height: 20px;
  border: 1px solid #999;
  border-radius: 10px;
  display: inline-block;
  vertical-align: middle;
  background: rgb(242, 242, 242);
  position: relative;
  margin-left: 1%;
  margin-right: 0.4rem;
}

input[type='radio'] {
  display: none;

  &.disabled {
    cursor: not-allowed;
  }
}

label {
  display: inline-block;
  width: #{$option-width};
  height: 18px;
  border-radius: 10px;
  position: absolute;
  text-align: center;
  border: 1px solid transparent;
}

.auto + label {
  left: 33%;
}

.auto:checked + label {
  color: #0a0a0a;
  background: rgba(200, 200, 200, 0.9);
  border: 1px solid rgb(200, 200, 200);
}

.on + label {
  right: 0;
}

.on:checked + label {
  color: #fff;
  background: rgba(113, 197, 122, 0.9);
  border: 1px solid rgb(113, 197, 122);
}

.off + label {
  left: 0;
}

.off:checked + label {
  color: #fff;
  background: rgba(229, 114, 114, 0.9);
  border: 1px solid rgb(229, 114, 114);
}
</style>
