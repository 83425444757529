<template>
  <div
    v-if="
      type === AlertStatus.ALERT ||
      type === AlertStatus.WARNING ||
      type === AlertStatus.SUCCESS ||
      type === AlertStatus.WARNING_HREF
    "
    class="ciam-alert border-solid border-2 border-gray"
  >
    <div class="flex title rounded">
      <div class="flex-shrink-0">
        <!-- Heroicon name: solid/x-circle -->
        <svg
          v-if="type === AlertStatus.ALERT"
          class="h-5 w-5 text-red-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >

          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clip-rule="evenodd"
          />
        </svg>

        <!-- Heroicon name: solid/exclamation -->
        <svg
          v-if="type === AlertStatus.WARNING || type === AlertStatus.WARNING_HREF"
          class="ml-2 h-5 w-5 text-yellow-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="grey"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>

        <!-- Heroicon name: solid/check-circle -->
        <svg
          v-if="type === AlertStatus.SUCCESS"
          class="h-5 w-5 text-green-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clip-rule="evenodd"
          />
        </svg>
      </div>

      <div
        v-if="type === AlertStatus.ALERT || type === AlertStatus.WARNING || type === AlertStatus.SUCCESS"
        class="ml-3"
      >
        <h3 class="text-sm font-medium text-black-800">
          {{ title }}
        </h3>
      </div>
      <div v-if="type === AlertStatus.WARNING_HREF" class="ml-3">
        
        <h3 class="text-sm font-medium text-black-800">
          {{ title }}
        </h3>

      </div>
    </div>
    <div class="description">
      <div class="ml-5 mt-2 text-sm text-grey-100">
        <p>
          {{ description }}
          <a class="text-gray-800" target="_blank" :href="href"><u>{{ hrefText }}</u></a>
        </p>
      </div>
    </div>
  </div>
  <div v-else-if="type === AlertStatus.INFO" class="ciam-info">
    <div class="flex">
      <div class="flex-shrink-0">
        <ciam-icon class="text-blue-800" name="fa-info"></ciam-icon>
      </div>

      <div class="ml-3">
        <h3 class="text-sm font-medium text-blue-800">
          {{ title }}
        </h3>
        <div class="mt-2 text-sm text-blue-700">
          <p>
            {{ description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export const AlertStatus = {
  WARNING: 'WARNING',
  WARNING_HREF: 'WARNING_HREF',
  ALERT: 'ALERT',
  SUCCESS: 'SUCCESS',
  INFO: 'INFO',
};

export default {
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: AlertStatus.ALERT,
      // eslint-disable-next-line no-prototype-builtins
      validator: (status) => AlertStatus.hasOwnProperty(status),
    },
    hrefText: {
      type: String,
      required: false,
    },
    href: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      AlertStatus: AlertStatus,
    };
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.ciam-alert {
  padding-top: 0;
  @apply rounded-md bg-white pb-4;

  &.primary {
    @apply flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
  }
}

.ciam-info {
  @apply rounded-md bg-blue-50 p-4;

  &.primary {
    @apply inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
  }
}
.title {
  background-color: rgb(254, 243, 199);
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
