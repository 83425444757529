<template>
  <ciam-page :title-suffix="$t('pageTitles.organization')">
    <ciam-card>
      <ciam-card-header>
        <template v-slot:header>
          <ciam-text>{{ $t('organization.edit.title', { name: organizationName }) }}</ciam-text>
        </template>
        <template v-slot:subtitle>{{ $t('organization.edit.subtitle') }}</template>
      </ciam-card-header>
      <Promised :promise="loadingPromise">
        <template v-slot:pending>
          <ciam-loader class="py-4"></ciam-loader>
        </template>
        <template v-slot:rejected="error">
          <ciam-alert :title="error.message" :description="error.message" :type="AlertStatus.ALERT"></ciam-alert>
        </template>
        <template>
          <div>
            <ciam-card-content>
              <ciam-card-content-lines>
                <ciam-card-content-line type="full-width" v-if="problem">
                  <template v-slot:content>
                    <ciam-alert :title="problem.title" :description="problem.detail" :type="AlertStatus.ALERT"></ciam-alert>
                  </template>
                </ciam-card-content-line>
                <ciam-card-content-line>
                  <template v-slot:label>
                    <ciam-text>{{ $t('organization.edit.name') }}</ciam-text>
                  </template>
                  <template v-slot:content>
                    <ciam-input type="text" v-model="form.name"></ciam-input>
                  </template>
                </ciam-card-content-line>
                <ciam-card-footer>
                  <ciam-button class="primary" :disabled="!isValid" @click="applyEditOrganization()">
                    {{ $t('actions.save') }}
                  </ciam-button>
                </ciam-card-footer>
              </ciam-card-content-lines>
            </ciam-card-content>
          </div>
        </template>
      </Promised>
    </ciam-card>
    <ciam-card class="mt-2">
      <ciam-card-header>
        <template v-slot:header>
          <ciam-text>{{ $t('organization.edit.multipleFactor.header') }}</ciam-text>
        </template>
        <template v-slot:subtitle>{{ $t('organization.edit.multipleFactor.subtitle') }}</template>
      </ciam-card-header>
      <Promised :promise="loadingPromise">
        <template>
          <div>
            <ciam-card-content>
              <ciam-card-content-lines>
                <ciam-card-content-line>
                  <template v-slot:label>
                    <ciam-text>{{ $t('organization.multipleFactors.status') }}</ciam-text>
                  </template>

                  <template v-slot:content>
                    <ul>
                      <li>
                        <ciam-two-states v-model="mfaStatus" />
                        <ciam-text>{{ $t('organization.multipleFactors.name') }}</ciam-text>
                      </li>
                    </ul>
                  </template>
                </ciam-card-content-line>
                <ciam-card-footer>
                  <ciam-button class="primary" :disabled="!policyChanged" @click="updateSecurityPolicy()">
                    {{ $t('actions.save') }}
                  </ciam-button>
                </ciam-card-footer>
              </ciam-card-content-lines>
            </ciam-card-content>
          </div>
        </template>
      </Promised>
    </ciam-card>
  </ciam-page>
</template>

<script>
import OrganizationService from '@/pages/organizations/OrganizationService';
import CiamCard from '@/components/CiamCard';
import CiamText from '@/components/CiamText';
import CiamCardContentLines from '@/components/CiamCardContentLines';
import CiamCardContentLine from '@/components/CiamCardContentLine';
import CiamInput from '@/components/CiamInput';
import CiamCardContent from '@/components/CiamCardContent';
import { Notification } from 'vue-notifyjs';
import i18n from '@/i18n';
import CiamAlert, { AlertStatus } from '@/components/CiamAlert';
import { routeNames } from '@/router';
import { intOr } from '@/util/fp';
import CiamTwoStates from '@/components/CiamTwoStates';
import CommonService from '@/pages/commonService';

export default {
  name: 'OrganizationEditModal',
  components: {
    CiamInput,
    CiamTwoStates,
    CiamAlert
  },
  data() {
    return {
      mfaStatus: 'enabled',
      organizationPolicies: [],
      AlertStatus: AlertStatus,
      loadingPromise: new Promise(() => {}),
      form: {
        name: 'default',
      },
      problem: null,
      organizationName: '...',
      organization: null,
    };
  },
  mounted() {
    let loader = this.$loading.show();
    this.form.name = '';
    this.loadingPromise = OrganizationService.get(this.organizationId)
      .then((organization) => {
        this.organization = organization;
        this.organizationName = organization.name;
        this.form.name = organization.name;
        this.getSecurityPolicy(this.organizationId);
      })
      .catch((reason) => {
        this.problem = reason;
      })
      .finally(() => loader.hide());
  },
  computed: {
    isValid() {
      return this.form.name.replaceAll(" ", "").length > 1 && this.form.name !== this.organizationName;
    },
    organizationId() {
      return this.$route.params.id;
    },
    getMfaStatus() {
      return this.organizationPolicies.mfaEnforced ? 'enabled' : 'disabled';
    },
    policyChanged() {
      return (
        (this.organizationPolicies.mfaEnforced && this.mfaStatus === 'disabled') ||
        (!this.organizationPolicies.mfaEnforced && this.mfaStatus === 'enabled')
      );
    },
  },
  methods: {
    async getSecurityPolicy() {
      this.organizationPolicies = await OrganizationService.getSecurityPolicy(this.organizationId);
      this.mfaStatus = this.organizationPolicies.mfaEnforced ? 'enabled' : 'disabled';
    },
    updateSecurityPolicy() {
      let loader = this.$loading.show();
      OrganizationService.updateSecurityPolicy(this.organizationId, this.mfaStatus === 'enabled' ? true : false)
        .then(this.getSecurityPolicy)
        .finally(() => {
          loader.hide();
          this.redirectAfterChange();
        });
    },
    applyEditOrganization() {
      OrganizationService.update(this.organizationId, {
        name: this.form.name,
      })
        .then(() => {
          Notification.notify({
            message: i18n.t('notifications.organizationUpdated'),
            type: 'info',
          });
          this.redirectAfterChange();
        })
        .catch((problem) => {
          this.problem = problem;
        });
    },
    redirectAfterChange() {
      this.$router
        .push({
          name: routeNames.OrganizationDetails,
          query: {
            organization_id: this.organizationId,
          },
          params: { id: this.organizationId },
        })
        .catch(() => {});
    },
  },
};
</script>
